/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from "react";
import { Canvas, useLoader, useFrame, useThree } from "@react-three/fiber";
import { useGLTF, OrbitControls } from "@react-three/drei";
import * as THREE from "three";
import { Vector3, Quaternion, Euler, StaticReadUsage } from "three";
import { AnimatePresence, motion, useTransform, useScroll } from "framer-motion";
import { useWindowSize, useWindowWidth, useWindowHeight } from "@react-hook/window-size";

const modelVariants = {
  offscreen: {
    opacity: 0,
  },
  onscreen: {
    opacity: 1,
    transition: {
      duration: 0.9,
      ease: [0.87, 0, 0.13, 1],
    },
  },
};

const Rig = ({ v = new Vector3() }) => {
  return useFrame((state) => {
    // console.log(state.mouse);
    state.camera.position.lerp(v.set((state.mouse.x + 1) * 4.2, state.mouse.y, Math.min(0, state.mouse.x) + 9), 0.05);
    state.camera.lookAt(3, 1.6, 0);
  });
};

const Model = (props: any) => {
  const w = useWindowWidth();
  const { nodes, materials } = useGLTF("/assets/model/ecosystem.glb");

  const quaternion = new THREE.Quaternion();
  quaternion.setFromAxisAngle(
    new THREE.Vector3(
      1,
      0.7,
      0
      // 1,
      // 0.6,
      // 0
      //   // (100 * Math.PI) / 180,
      //   // (130 * Math.PI) / 180,
      //   // (-300 * Math.PI) / 180
      //   0,
      //   0,
      //   0
    ),
    // Math.PI / 180
    (Math.PI / 180) * -45
  );
  const vector = new THREE.Vector3(0, 0, 0);
  vector.applyQuaternion(quaternion);

  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.ecosystem.geometry}
        material={materials.マテリアル}
        // position={[0,0,0]}
        // rotation={[2.4, 1, 0]}
        // scale={0.06}
        quaternion={quaternion}
      />
    </group>
  );
};

const EcoModel = () => {
  const w = useWindowWidth();

  const positionY = w / 375;

  return (
    <AnimatePresence>
      <motion.div
        className="md:h-587 md:w-683"
        style={{
          gridArea: "1/1",
        }}
        variants={modelVariants}
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true, amount: 0.5 }}
      >
        <Canvas
          camera={{
            fov: 75,
            near: 1,
            far: 1000,
            position: [0, 0, 1],
          }}
        >
          {/* <OrbitControls
            enableZoom={false}
            enablePan={false}
            enableDamping={false}
          /> */}
          {/* <CameraController  /> */}
          <Rig />
          <Model
            position={w < 834 ? [6, 2.7, 0] : [5.5, 2.5, 0]}
            // rotation={w < 834 ? [2.1, 0.9, 0] : [2.5, 0.9, 0]}
            rotation={[0, 0, 0]}
            scale={w < 834 ? 0.025 : 0.048}
          />
        </Canvas>
      </motion.div>
    </AnimatePresence>
  );
};

export default EcoModel;
