import React from "react";
import { Link, useI18next } from "gatsby-plugin-react-i18next";
import { motion } from "framer-motion";
import { useWindowWidth } from "@react-hook/window-size";

const ttlContainer = {
  init: {},
  enter: {
    transition: {
      staggerChildren: 0.05,
    },
  },
};

const TopContact = () => {
  const { t } = useI18next();
  const w = useWindowWidth();

  const ttl = "Contact";

  return (
    <section className="mt-168 md:mt-230">
      <div className="mx-auto w-325 md:w-auto">
        <motion.h2
          className="sec-title flex h-64 justify-center overflow-hidden md:h-128"
          variants={ttlContainer}
          initial="init"
          whileInView="enter"
          viewport={{ once: true, amount: 0.5 }}
        >
          {ttl.split("").map((t, i) => {
            return (
              <motion.span
                key={i}
                className="inline-block"
                variants={{
                  init: {
                    y: w < 834 ? 3.75 + "rem" : 7.5 + "rem",
                  },
                  enter: {
                    y: 0,
                    transition: {
                      duration: 0.6,
                      ease: "easeInOut",
                    },
                  },
                }}
              >
                {t}
              </motion.span>
            );
          })}
        </motion.h2>
        <motion.p
          className="mb-40 flex justify-center text-16 font-medium leading-1.8 md:text-24 md:leading-none"
          variants={{
            init: {
              opacity: 0,
              y: 10,
            },
            enter: {
              opacity: 1,
              y: 0,
              transition: {
                duration: 0.5,
              },
            },
          }}
          initial="init"
          whileInView="enter"
          viewport={{ once: true, amount: 0.5 }}
        >
          {t("contactDesc")}
        </motion.p>
        <Link
          to="/contact"
          className="stroke-contact mx-auto flex h-50 w-155 items-center justify-center rounded-full bg-navy font-varela text-24 text-white"
        >
          Contact
        </Link>
      </div>
    </section>
  );
};

export default TopContact;
