import React from "react";
import { useGlobalStore } from "../store/global";
import { AnimatePresence, motion } from "framer-motion";

const Loading = () => {
  const { isLoad, setLoad } = useGlobalStore();

  return (
    <AnimatePresence>
      <motion.div
        className="fixed flex h-full-vh w-full items-center justify-center bg-00blk"
        style={{
          zIndex: 200,
        }}
        variants={{
          hidden: {
            opacity: 0,
            transition: {
              duration: 1,
            },
          },
          show: {
            opacity: 1,
          },
        }}
        initial="hidden"
        animate={isLoad ? "show" : "hidden"}
      >
        <div className="relative h-22px w-200px md:h-79px md:w-168px">
          <picture>
            <source media="(max-width: 833px)" srcSet="/assets/img/common/loading_v2-sp.png" />
            <img
              src="/assets/img/common/loading-v2.png"
              className="absolute h-full w-full"
              width="168"
              height="79"
              alt="WHOLE EARTH FOUNDATION"
            />
          </picture>
          <motion.img
            className="absolute inset-y-0 left-0 my-auto h-21px w-21px md:top-0 md:left-auto md:right-17px md:my-0 md:h-54px md:w-54px"
            src="/assets/img/common/loading-inner.png"
            width="54"
            height="54"
            alt="WHOLE EARTH FOUNDATION"
            animate={{ rotate: 360 }}
            transition={{
              repeat: Infinity,
              repeatType: "loop",
              duration: 2,
              ease: "linear",
            }}
          />
        </div>
      </motion.div>
    </AnimatePresence>
  );
};
export default Loading;
