import React, { useState } from "react";
import { useEffect, useRef } from "react";
import { useGlobalStore } from "../../store/global";
import * as THREE from "three";
import { useWindowWidth, useWindowHeight } from "@react-hook/window-size";
import { AnimatePresence, motion, useTransform, MotionValue } from "framer-motion";
import GUI from "lil-gui";

interface Props {
  scrollYProgress: MotionValue<number>;
}

const EarthModel = ({ scrollYProgress }: Props) => {
  const { isLoad, setLoad } = useGlobalStore();
  const w = useWindowWidth();
  const h = useWindowHeight();
  const [shadowSize, setShadowSize] = useState(0);
  const [shadowPosition, setShadowPosition] = useState(0);

  const canvasRef = useRef<HTMLDivElement>(null);
  const opacity = useTransform(scrollYProgress, [0, 0.8, 1], [0.7, 0.1, 0]);

  useEffect(() => {
    // const gui = new GUI();
    //レンダラー作成
    const renderer = new THREE.WebGLRenderer({
      alpha: true,
    });
    renderer.outputEncoding = THREE.sRGBEncoding;
    renderer.toneMapping = THREE.LinearToneMapping;

    //キャンバス作成
    const elm = canvasRef.current;
    elm?.appendChild(renderer.domElement);

    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(window.innerWidth, window.innerHeight);

    //シーン作成
    const scene = new THREE.Scene();

    //カメラ作成
    const camera = new THREE.PerspectiveCamera(45, window.innerWidth / window.innerHeight, 1, 10000);
    camera.position.set(0, 0, +1000);
    // const controls: OrbitControls = new OrbitControls(camera, renderer.domElement);

    //textureローダー
    const loader = new THREE.TextureLoader();

    ////////////////地球モデル////////////////////////////////
    const earthGeometry = new THREE.SphereGeometry(300, 30, 30);
    const earthTexture = loader.load("/assets/img/texture/earthmap.jpg");
    earthTexture.encoding = THREE.sRGBEncoding;
    const earthMaterial = new THREE.MeshPhongMaterial({
      map: earthTexture,
      shininess: 15,
    });
    const earth = new THREE.Mesh(earthGeometry, earthMaterial);
    if (w < 834) {
      // earth.scale.set(0.45, 0.45, 0.45);
      earth.scale.set(0.55, 0.55, 0.55);
    } else {
      earth.scale.set(0.8, 0.8, 0.8);
    }
    ////////////////地球モデルここまで////////////////////////////////

    ////////////////雲モデル////////////////////////////////
    const cloudTexture = loader.load("/assets/img/texture/cloud.png");
    earthTexture.encoding = THREE.sRGBEncoding;

    const cloudMaterial = new THREE.MeshPhongMaterial({
      map: cloudTexture,
      transparent: true,
    });
    const cloudGeometry = new THREE.SphereGeometry(300, 30, 30);
    const cloud = new THREE.Mesh(cloudGeometry, cloudMaterial);
    if (w < 834) {
      // cloud.scale.set(0.46, 0.46, 0.46);
      cloud.scale.set(0.56, 0.56, 0.56);
    } else {
      cloud.scale.set(0.81, 0.81, 0.81);
    }
    ////////////////雲モデルここまで////////////////////////////////

    const group = new THREE.Group();
    group.rotation.set(0, 0, -(23.4 * Math.PI) / 180);
    if (w < 834) {
      group.position.set(0, -200, 0);
    } else {
      group.position.set(0, 0, 0);
    }

    group.add(earth);
    group.add(cloud);
    scene.add(group);

    THREE.DefaultLoadingManager.onLoad = () => {
      if (!sessionStorage.getItem("visited")) {
        sessionStorage.setItem("visited", "first");
        setTimeout(() => {
          setLoad(false);
        }, 5500);
      } else {
        setLoad(false);
      }
    };

    const ambientLight = new THREE.AmbientLight(0xffffff, -1);
    ambientLight.position.set(-4, -2, 0);
    ambientLight.castShadow = true;
    ambientLight.receiveShadow = true;
    scene.add(ambientLight);

    const directionalLight = new THREE.DirectionalLight(0xffffff, 2.7);
    directionalLight.position.set(-2, -1, 8);
    directionalLight.castShadow = true;
    directionalLight.receiveShadow = true;
    scene.add(directionalLight);

    // gui.add(ambientLight, "intensity", -10, 10, 0.1).name("ambientLight");
    // gui.add(ambientLight.position, "x", -10, 10, 1).name("x");
    // gui.add(ambientLight.position, "y", -10, 10, 1).name("y");
    // gui.add(ambientLight.position, "z", -10, 10, 1).name("z");

    // gui.add(directionalLight, "intensity", -10, 10, 0.1).name("directintensity");
    // gui.add(directionalLight.position, "x", -50, 50, 1).name("x");
    // gui.add(directionalLight.position, "y", -50, 50, 1).name("y");
    // gui.add(directionalLight.position, "z", -50, 50, 1).name("z");

    // gui.add(earthMaterial, "shininess", 0, 1000, 1).name("specular");

    tick();
    onResize();
    getShadowStyle();

    function tick() {
      earth.rotation.y += 0.0001;
      cloud.rotation.y += 0.0002;

      renderer.render(scene, camera);
      requestAnimationFrame(tick);
    }

    function onResize() {
      renderer.setPixelRatio(window.devicePixelRatio);
      renderer.setSize(window.innerWidth, window.innerHeight);

      // カメラのアスペクト比を正す
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
    }

    function getShadowStyle() {
      let size;
      let position;
      if (834 <= window.innerWidth && 400 <= window.innerHeight) {
        size = (window.innerHeight * 242) / 400;
        setShadowSize(size);
        setShadowPosition(0);
      } else if (window.innerWidth <= 833 && 556 <= window.innerHeight) {
        size = (window.innerHeight * 191) / 556;
        position = (window.innerHeight * 46) / 556;
        setShadowSize(size);
        setShadowPosition(position);
      }
    }

    window.addEventListener("resize", () => {
      onResize();
    });

    window.addEventListener("resize", getShadowStyle);

    return () => {
      elm?.removeChild(renderer.domElement);
    };
  }, []);

  return (
    <AnimatePresence>
      <div className="absolute h-full-vh w-full">
        <motion.div
          ref={canvasRef}
          className="absolute z-min h-full-vh w-full"
          style={{
            opacity: opacity,
          }}
        ></motion.div>
        {/* <div
          className="absolute inset-x-0 z-min mx-auto rounded-full shadow md:inset-0 md:m-auto"
          style={{
            width: shadowSize,
            height: shadowSize,
            bottom: shadowPosition,
          }}
        ></div> */}
      </div>
    </AnimatePresence>
  );
};

export default EarthModel;
