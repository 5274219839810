import { memo } from "react";
import { Link } from "gatsby-plugin-react-i18next";
import { INewsListItem } from "../../types";

export const NewsItem = memo(function NewsItem({
  thumbnail,
  publishedDate,
  title,
  externalLink,
  slug,
}: Omit<INewsListItem, "lang">) {
  const container = (
    <article className="flex items-start">
      <div className="aspect-full mt-10 w-70 shrink-0 overflow-hidden rounded-full md:m-0 md:w-120">
        <img
          src={`${thumbnail.url}?w=120&fm=webp&dpr=2`}
          alt=""
          width={thumbnail.width}
          height={thumbnail.height}
          className="absolute top-0 left-0 h-full w-full object-cover"
        />
      </div>
      <div className="ml-15 text-navy md:ml-40 md:mt-7">
        <time className="font-varela text-13 leading-none tracking-[-0.02em] md:text-14">{publishedDate}</time>
        <h1 className="mt-12 text-12 leading-[1.5] hover:underline md:mt-10 md:text-16 md:leading-[1.8]">{title}</h1>
      </div>
    </article>
  );

  if (externalLink) {
    return (
      <a href={externalLink} target="_blank" rel="noreferrer" className="block">
        {container}
      </a>
    );
  } else {
    return (
      <Link to={`/news/${slug}/`} className="block">
        {container}
      </Link>
    );
  }
});
