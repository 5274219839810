import React from "react";
import { motion } from "framer-motion";
import { useWindowWidth } from "@react-hook/window-size";

const ttlContainer = {
  init: {},
  enter: {
    transition: {
      staggerChildren: 0.05,
    },
  },
};

const Media = () => {
  const w = useWindowWidth();

  const ttl = "Media";

  return (
    <section className="pt-150px md:mt-80" id="media">
      <motion.h2
        className="sec-title flex h-64 justify-center overflow-hidden md:h-128"
        variants={ttlContainer}
        initial="init"
        whileInView="enter"
        viewport={{ once: true, amount: 0.5 }}
      >
        {ttl.split("").map((t, i) => {
          return (
            <motion.span
              key={i}
              className="inline-block"
              variants={{
                init: {
                  y: w < 834 ? 3.75 + "rem" : 7.5 + "rem",
                },
                enter: {
                  y: 0,
                  transition: {
                    duration: 0.6,
                    ease: "easeInOut",
                  },
                },
              }}
            >
              {t}
            </motion.span>
          );
        })}
      </motion.h2>
      <picture>
        <source media="(max-width: 833px)" srcSet="/assets/img/media/media-sp.png" />
        <img
          src="/assets/img/media/media.png"
          className="mx-auto w-325 md:h-240 md:w-1162"
          width="1162"
          height="240"
          alt="Media"
        />
      </picture>
    </section>
  );
};

export default Media;
