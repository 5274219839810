import React from "react";
import { useI18next } from "gatsby-plugin-react-i18next";
import { motion } from "framer-motion";
import { useWindowWidth } from "@react-hook/window-size";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Navigation, EffectFade, EffectCreative } from "swiper";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/effect-fade";
import "swiper/css/effect-creative";
import "swiper/css/autoplay";
import ToolsModel from "../model/ToolsModel";
SwiperCore.use([Autoplay, Navigation, EffectFade, EffectCreative]);

const slideImages = ["slide1", "slide2", "slide3", "slide4"];

const ttlContainer = {
  init: {},
  enter: {
    transition: {
      staggerChildren: 0.05,
    },
  },
};

const ToolsSwiper = () => {
  return (
    <div
      className="tools-shadow md:tools-shadow-pc relative mt-632 md:mt-374 md:ml-[-4.375rem] md:h-805 md:w-633"
      style={{
        gridArea: "1/1",
      }}
    >
      <div className="pointer-events-none absolute top-0 left-[-3.75rem] z-10 md:inset-x-0">
        <img
          className="h-402 w-316 md:h-805 md:w-633"
          src="/assets/img/tool/frame_v2.png"
          width="633"
          height="805"
          alt="TEKKON"
        />
      </div>
      <Swiper
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        loop={true}
        speed={1000}
        effect="slide"
        direction="vertical"
        slidesPerView={1}
        className="absolute top-27 left-108 h-307 w-120 skew-x-[12.5deg] skew-y-[-28deg] rounded-[1.2rem] md:top-58 md:left-338 md:h-614 md:w-239 md:rounded-[2rem]"
        style={{
          marginLeft: 0,
          marginRight: 0,
        }}
      >
        {slideImages.map((s, i) => {
          return (
            <SwiperSlide key={i}>
              <img
                src={`/assets/img/tool/${s}.jpg`}
                className="h-307 w-120 rounded-[1.2rem] md:h-614 md:w-239 md:rounded-[2rem]"
                style={{
                  gridArea: "1/1",
                }}
                width="239"
                height="614"
                alt="TEKKON"
              />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

const Tools = () => {
  const { t } = useI18next();
  const w = useWindowWidth();

  const ttl = "Tools";

  return (
    <section className="bgImageSp bgStyle md:bgImagePc relative mt-221 md:mt-150" id="tools">
      <div className="mx-auto grid w-325 pb-148 md:w-1236 md:pb-210">
        <div
          className="mt-14 w-325 md:mt-113 md:ml-703 md:w-auto"
          style={{
            gridArea: "1/1",
          }}
        >
          <motion.h2
            className="sec-title h-64 overflow-hidden md:h-128"
            variants={ttlContainer}
            initial="init"
            whileInView="enter"
            viewport={{ once: true, amount: 0.5 }}
          >
            {ttl.split("").map((t, i) => {
              return (
                <motion.span
                  key={i}
                  className="inline-block"
                  variants={{
                    init: {
                      y: w < 834 ? 3.75 + "rem" : 7.5 + "rem",
                    },
                    enter: {
                      y: 0,
                      transition: {
                        duration: 0.6,
                        ease: "easeInOut",
                      },
                    },
                  }}
                >
                  {t}
                </motion.span>
              );
            })}
          </motion.h2>
          <motion.p
            className="mb-20 whitespace-pre-wrap text-24 font-medium leading-1.8 md:mb-25 md:w-auto"
            variants={{
              init: {
                opacity: 0,
                y: 10,
              },
              enter: {
                opacity: 1,
                y: 0,
                transition: {
                  duration: 0.5,
                },
              },
            }}
            initial="init"
            whileInView="enter"
            viewport={{ once: true, amount: 0.5 }}
          >
            {t("toolsHead")}
          </motion.p>
          <motion.p
            className="whitespace-pre-wrap text-14 leading-1.8 md:mb-80"
            variants={{
              init: {
                opacity: 0,
                y: 10,
              },
              enter: {
                opacity: 1,
                y: 0,
                transition: {
                  duration: 0.5,
                },
              },
            }}
            initial="init"
            whileInView="enter"
            viewport={{ once: true, amount: 0.5 }}
          >
            {t("toolsText")}
          </motion.p>
        </div>
        <div
          className="grid"
          style={{
            gridArea: "1/1",
          }}
        >
          <ToolsModel />
          <ToolsSwiper />
        </div>
        <div
          className="mt-1138 ml-0 w-325 md:mt-752 md:ml-703 md:w-auto"
          style={{
            gridArea: "1/1",
          }}
        >
          <div className="mb-80">
            <h3 className="mb-20">
              <img className="h-43 w-240" src="/assets/img/tool/tekkonlogo.png" width="240" height="43" alt="TEKKON" />
            </h3>
            <motion.p
              className="mb-20 whitespace-pre-wrap text-14 leading-1.8"
              variants={{
                init: {
                  opacity: 0,
                  y: 10,
                },
                enter: {
                  opacity: 1,
                  y: 0,
                  transition: {
                    duration: 0.5,
                  },
                },
              }}
              initial="init"
              whileInView="enter"
              viewport={{ once: true, amount: 0.5 }}
            >
              {t("toolsTekkon")}
            </motion.p>
            <a
              href="https://tekkon.com/"
              target="_blank"
              rel="noreferrer"
              className="group relative my-0 mr-0 ml-auto flex h-50 w-237 items-center"
            >
              <span className="btn-link w-202">More Detail</span>
              <img
                src="/assets/img/common/btn-arrow.svg"
                className="absolute right-0 h-23 w-50 duration-[0.2s] group-hover:right-[-1rem]"
                width="50"
                height="23"
                alt="More Detail"
              />
            </a>
          </div>
          <div>
            <h3 className="mb-20 whitespace-pre-wrap text-24 leading-1.8">
              <img
                className="h-59 w-221"
                src="/assets/img/tool/hole.png"
                width="221"
                height="59"
                alt="TEKKON インフラ聖戦"
              />
            </h3>
            <motion.p
              className="mb-40 whitespace-pre-wrap text-14 leading-1.8"
              variants={{
                init: {
                  opacity: 0,
                  y: 10,
                },
                enter: {
                  opacity: 1,
                  y: 0,
                  transition: {
                    duration: 0.5,
                  },
                },
              }}
              initial="init"
              whileInView="enter"
              viewport={{ once: true, amount: 0.5 }}
            >
              {t("toolsHoleText")}
            </motion.p>
            <a
              href="https://event.tekkon.com/"
              target="_blank"
              rel="noreferrer"
              className="group relative my-0 mr-0 ml-auto flex h-50 w-237 items-center"
            >
              <span className="btn-link w-202">More Detail</span>
              <img
                src="/assets/img/common/btn-arrow.svg"
                className="absolute right-0px h-23 w-50 duration-[0.2s] group-hover:right-[-1rem]"
                width="50"
                height="23"
                alt="More Detail"
              />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Tools;
