import clsx from "clsx";
import { useI18next } from "gatsby-plugin-react-i18next";
import React, { memo, useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { BusinessIconType, IBusinessLink, IMember } from "../../types";
import { IconGithub, IconLink, IconLinkedin, IconWiki } from "../commons/Icon";

interface ModalItem {
  isVisible: boolean;
  onClose: () => void;
  children?: React.ReactNode;
}

const overlay = {
  init: {
    opacity: 0,
    x: 100,
  },
  enter: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.5,
    },
  },
  exit: {
    opacity: 0,
  },
};

const Modal = ({ isVisible, onClose, children }: ModalItem) => {
  return (
    <>
      <AnimatePresence>
        {isVisible && (
          <motion.div
            className="fixed top-0 left-0 z-200 flex h-full-vh w-full items-center justify-center bg-[#657989]"
            variants={overlay}
            initial="init"
            animate="enter"
            exit="exit"
            onClick={onClose}
          >
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const businessIcon: Record<BusinessIconType, any> = {
  linkedin: IconLinkedin,
  github: IconGithub,
  wikipedia: IconWiki,
  none: IconLink,
};

const BusinessLink = ({ businessLink }: { businessLink: IBusinessLink }) => {
  const Icon = businessIcon[businessLink.icon[0]];

  return (
    <div className="mt-15 text-center md:mt-20">
      <a href={businessLink.url} target="_blank" className="inline-block" rel="noreferrer">
        <Icon />
      </a>
    </div>
  );
};

export const Member = memo(function Member({ id, photo, position, name, enName, cv, businessLink }: IMember) {
  const { language } = useI18next();

  const [isVisible, setIsVisible] = useState(false);
  const [selectitem, setSelectItem] = useState("");

  useEffect(() => {
    if (isVisible) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  }, [isVisible]);

  const openModal = (id: string) => {
    setIsVisible(true);
    setSelectItem(id);
  };

  const closeModal = () => {
    setIsVisible(false);
  };

  return (
    <>
      <article className="w-full">
        <div
          className="cursor-pointer"
          onClick={() => {
            if (!isVisible) {
              openModal(id);
            } else {
              closeModal();
            }
          }}
        >
          <div className="aspect-full w-full overflow-hidden rounded-full bg-memberbg md:mx-auto md:w-186">
            {photo?.url && (
              <img
                src={`${photo?.url}?w=372&fm=webp&dpr=2`}
                alt={name}
                width={photo?.width}
                height={photo?.height}
                className="absolute top-0 left-0 h-full w-full"
              />
            )}
          </div>
          <div
            className={clsx("md:mt-30", {
              "mt-20": !position,
              "mt-10": position,
            })}
          >
            <div className="flex flex-col items-center justify-center md:flex-row">
              {position && <p className="font-varela text-12 leading-none">{position}</p>}
              <h1
                className={clsx(
                  "stroke-member-name text-center font-varela text-16 leading-none tracking-[-0.02em] md:text-22",
                  { "mt-10 md:mt-0 md:ml-10": position }
                )}
              >
                {name}
              </h1>
            </div>
            {language === "ja" && <p className="mt-5 text-center font-varela text-12 leading-none md:mt-8">{enName}</p>}
          </div>
        </div>
        {/* {businessLink !== null && (businessLink.icon.length > 0 || businessLink?.url !== undefined) && (
          <BusinessLink businessLink={businessLink} />
        )} */}
        {businessLink && <BusinessLink businessLink={businessLink} />}
      </article>
      <Modal isVisible={isVisible && id == selectitem} onClose={() => setIsVisible(false)}>
        <motion.div
          className="modalBg relative h-410 w-325 py-40 pl-20 pr-44 md:h-519 md:w-535 md:px-80 md:pt-66 md:pb-80"
          initial={{ opacity: 0, x: 10 }}
          animate={{
            opacity: 1,
            x: 0,
            transition: {
              duration: 0.3,
              delay: 0.1,
            },
          }}
          exit={{
            opacity: 0,
            x: -10,
          }}
        >
          <h3 className="mb-20  text-24 font-medium md:mb-30">
            <span className="font-valera mb-10 block text-12">{position}</span>
            <span className="items-center md:flex">
              {name}
              {language === "ja" && <span className="mt-10 block font-varela text-12 md:ml-10 md:mt-0">{enName}</span>}
            </span>
          </h3>
          <div className="h-257 w-full overflow-y-auto overflow-x-hidden md:h-310">
            <p className="word-spacing whitespace-pre-wrap text-13 leading-1.5">{cv}</p>
          </div>
          <div className="absolute top-20 right-20 cursor-pointer md:top-29 md:right-30">
            <div className="relative mb-10 h-30 w-30">
              <span className="absolute inset-y-0 my-auto block h-1px w-full rotate-45 bg-navy"></span>
              <span className="absolute inset-y-0 my-auto block h-1px w-full rotate-[-45deg] bg-navy"></span>
            </div>
            <div className="text-center font-varela text-10px">Close</div>
          </div>
        </motion.div>
      </Modal>
    </>
  );
});
