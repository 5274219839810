import React, { useEffect } from "react";
import { graphql, PageProps } from "gatsby";
import { useI18next } from "gatsby-plugin-react-i18next";
import { Helmet } from "react-helmet";
import { useGlobalStore } from "../store/global";

import Layout from "../components/Layout";
import Fv from "../components/top/Fv";
import TopContact from "../components/top/Contact";
import About from "../components/top/About";
import Ecosystem from "../components/top/Ecosystem";
import Media from "../components/top/Media";
import Tools from "../components/top/Tools";
import Whitepaper from "../components/top/Whitepaper";
import Loading from "../components/Loading";
import { IMember, INews, IRoadmap } from "../types";
import { Team } from "../components/top/Team";
import { NewsSec } from "../components/top/NewsSec";
import { RoadmapSec } from "../components/top/Roadmap";

const IndexPage = ({ data }: PageProps<Queries.IndexPageQuery>) => {
  const { t } = useI18next();
  const { isLoad, setLoad } = useGlobalStore();

  const teamData = t<string, IMember[]>("team", { returnObjects: true });
  const roadmapData = t<string, IRoadmap>("roadmap", { returnObjects: true });
  const newsData = data.allNews.nodes as INews[];

  useEffect(() => {
    setLoad(true);
  }, []);

  const title = t("siteName");

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta property="og:title" content={title} />
        <meta name="twitter:title" content={title} />
      </Helmet>
      {isLoad && <Loading />}
      <Layout>
        <main className="pb-150 md:pb-230">
          <Fv />
          <div className="relative overflow-hidden">
            <div className="grad absolute top-0 left-0 h-400 w-full md:h-376"></div>
            <About />
            <Ecosystem />
            <Tools />
            <Whitepaper />
            <Team team={teamData} />
            <RoadmapSec roadmap={roadmapData} />
            <NewsSec news={newsData} />
            <Media />
            <TopContact />
          </div>
        </main>
      </Layout>
    </>
  );
};

export const query = graphql`
  query IndexPage($language: String!) {
    locales: allLocale(filter: { ns: { in: ["top", "meta"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allNews: allNews(filter: { lang: { eq: $language } }) {
      nodes {
        id
        slug
        publishedDate
        thumbnail {
          url
          width
          height
        }
        title
        externalLink
      }
    }
  }
`;

export default IndexPage;

// export const Head: HeadFC = () => <title>top</title>;
