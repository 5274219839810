import React from "react";
import { motion } from "framer-motion";
import { useWindowWidth } from "@react-hook/window-size";

import { IRoadmap } from "../../types";

interface IRoadmapSec {
  roadmap: IRoadmap;
}

const ttlContainer = {
  init: {},
  enter: {
    transition: {
      staggerChildren: 0.05,
    },
  },
};

export const RoadmapSec = ({ roadmap }: IRoadmapSec) => {
  const w = useWindowWidth();
  const { quarter, milestones } = roadmap;

  const ttl = "Roadmap";

  return (
    <section className="bgStyle bgImageYeSp md:bgImageYePc relative mt-150 md:mt-240" id="roadmap">
      <div className="px-25 pt-110 pb-192 md:px-0 md:pt-135 md:pb-150">
        <motion.h2
          className="sec-title flex h-75 justify-center overflow-hidden md:mb-70 md:h-140"
          variants={ttlContainer}
          initial="init"
          whileInView="enter"
          viewport={{ once: true, amount: 0.5 }}
        >
          {ttl.split("").map((t, i) => {
            return (
              <motion.span
                key={i}
                className="inline-block"
                variants={{
                  init: {
                    y: w < 834 ? 4.6875 + "rem" : 8.75 + "rem",
                  },
                  enter: {
                    y: 0,
                    transition: {
                      duration: 0.6,
                      ease: "easeInOut",
                    },
                  },
                }}
              >
                {t}
              </motion.span>
            );
          })}
        </motion.h2>
        <div className="mx-auto md:w-529">
          {quarter.map((quarter, i) => (
            <motion.dl
              key={i}
              className="grid grid-cols-roadmap gap-x-12 md:grid-cols-mdRoadmap md:gap-x-44 [&:nth-of-type(n+2)]:mt-21 md:[&:nth-of-type(n+2)]:mt-29"
              variants={{
                init: {
                  opacity: 0,
                  y: 10,
                },
                enter: {
                  opacity: 1,
                  y: 0,
                  transition: {
                    duration: 0.5,
                  },
                },
              }}
              initial="init"
              whileInView="enter"
              viewport={{ once: true, amount: 0.5 }}
            >
              <dt className="text-right text-14 font-medium leading-[1.5] md:text-16 md:leading-[1.8]">
                {quarter.yearQuarter}
              </dt>
              <dd className="text-14 font-medium leading-[1.5] md:text-16 md:leading-[1.8] md:tracking-[-0.02em]">
                {quarter.quarterList.map((item, i) => (
                  <p key={i} className="pl-[1em] indent-[-1em] before:content-['・']">
                    {item}
                  </p>
                ))}
              </dd>
            </motion.dl>
          ))}
          {milestones.isDisplay && (
            <motion.dl
              className="mt-21 grid grid-cols-roadmap gap-x-12 md:mt-29 md:grid-cols-mdRoadmap md:gap-x-44"
              variants={{
                init: {
                  opacity: 0,
                  y: 10,
                },
                enter: {
                  opacity: 1,
                  y: 0,
                  transition: {
                    duration: 0.5,
                  },
                },
              }}
              initial="init"
              whileInView="enter"
              viewport={{ once: true, amount: 0.5 }}
            >
              <dt className="text-right text-14 font-medium leading-[1.5] md:text-16 md:leading-[1.8]">
                {milestones.heading}
              </dt>
              <dd className="text-14 font-medium leading-[1.5] md:text-16 md:leading-[1.8] md:tracking-[-0.02em]">
                {milestones.contents.map((content, i) => (
                  <p key={i} className="pl-[1em] indent-[-1em] before:content-['・']">
                    {content}
                  </p>
                ))}
              </dd>
            </motion.dl>
          )}
        </div>
      </div>
    </section>
  );
};
