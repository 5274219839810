import React, { useEffect, useRef, useState } from "react";
import { MotionValue } from "framer-motion";

import lottie from "lottie-web";
import animAll from "../data/lottie_all.json";

interface Props {
  scrollYProgress: MotionValue<number>;
}

const EarthSvg = ({ scrollYProgress }: Props) => {
  const lottieRef = useRef(null);
  const [svgSize, setSvgSize] = useState(0);
  const [svgPosition, setSvgPosition] = useState(0);

  function getSvgStyle() {
    let size;
    let position;

    if (window.innerWidth <= 833 && 556 <= window.innerHeight) {
      size = (window.innerHeight * 200) / 556;
      position = (window.innerHeight * 326) / 556;
      setSvgSize(size);
      setSvgPosition(position);
    } else if (834 <= window.innerWidth && 400 <= window.innerHeight) {
      size = (window.innerHeight * 250) / 400;
      setSvgSize(size);
      setSvgPosition(0);
    } else {
      //seのsafariだけリサイズが効かなかったので、よくないけど
      size = (window.innerHeight * 200) / 556;
      position = (window.innerHeight * 326) / 556;
      setSvgSize(size);
      setSvgPosition(position);
    }
  }

  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: lottieRef.current!,
      renderer: "svg",
      loop: false,
      autoplay: false,
      animationData: animAll,
    });
    animatebodymovin();
    function animatebodymovin() {
      scrollYProgress.onChange((y) => {
        const maxFrame = anim.totalFrames - 13;
        const startFrame = maxFrame * y;
        anim.goToAndStop(startFrame, true);
      });
    }
    getSvgStyle();

    window.addEventListener("resize", () => {
      getSvgStyle();
    });

    return () => {
      anim.destroy();
    };
  }, []);

  return (
    <div className="absolute z-min h-full-vh w-full">
      <div
        ref={lottieRef}
        className="absolute inset-x-0 mx-auto md:inset-0 md:m-auto"
        style={{
          top: svgPosition,
          width: svgSize,
          height: svgSize,
        }}
      ></div>
    </div>
  );
};

export default EarthSvg;
