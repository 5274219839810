import React from "react";
import { useI18next } from "gatsby-plugin-react-i18next";
import { motion } from "framer-motion";
import { useWindowWidth } from "@react-hook/window-size";

const draw = {
  hidden: { pathLength: 0, opacity: 0 },
  visible: (i: number) => {
    const delay = i * 0.5;
    return {
      pathLength: 1,
      opacity: 1,
      transition: {
        pathLength: { delay, duration: 0.5 },
        opacity: { delay, duration: 0.01 },
      },
    };
  },
};

const ttlContainer = {
  init: {},
  enter: {
    transition: {
      staggerChildren: 0.05,
    },
  },
};

const Whitepaper = () => {
  const { t } = useI18next();
  const w = useWindowWidth();

  const ttl = "White Paper";

  return (
    <section className="mt-110 md:pt-100px" id="whitepaper">
      <div className="mx-auto grid w-325 md:w-1160">
        <div
          className="ml-152 grid md:ml-509"
          style={{
            gridArea: "1/1",
          }}
        >
          <motion.svg
            className="none-sp ml-244 h-361 w-361"
            style={{
              gridArea: "1/1",
            }}
            width="361"
            height="361"
            viewBox="0 0 361 361"
            fill="none"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
          >
            <motion.line y1="0.5" x2="360" y2="0.5" stroke="#A9C8E2" variants={draw} custom={0.1} />
            <motion.line y1="71.5" x2="360" y2="71.5" stroke="#A9C8E2" variants={draw} custom={0.5} />
            <motion.line y1="143.5" x2="360" y2="143.5" stroke="#A9C8E2" variants={draw} custom={0.3} />
            <motion.line y1="215.5" x2="360" y2="215.5" stroke="#A9C8E2" variants={draw} custom={0.2} />
            <motion.line y1="287.5" x2="360" y2="287.5" stroke="#A9C8E2" variants={draw} custom={0.1} />
            <motion.line y1="359.5" x2="360" y2="359.5" stroke="#A9C8E2" variants={draw} custom={0.3} />
            <motion.line x1="0.5" y1="1" x2="0.499984" y2="360" stroke="#A9C8E2" variants={draw} custom={0.9} />
            <motion.line x1="78.5" y1="1" x2="78.5" y2="360" stroke="#A9C8E2" variants={draw} custom={0.4} />
            <motion.line x1="150.5" y1="1" x2="150.5" y2="360" stroke="#A9C8E2" variants={draw} custom={0.3} />
            <motion.line x1="222.5" y1="1" x2="222.5" y2="360" stroke="#A9C8E2" variants={draw} custom={0.4} />
            <motion.line x1="294.5" y1="1" x2="294.5" y2="360" stroke="#A9C8E2" variants={draw} custom={0.3} />
            <motion.line
              x1="360.5"
              y1="2.18555e-08"
              x2="360.5"
              y2="360"
              stroke="#A9C8E2"
              variants={draw}
              custom={0.6}
            />
          </motion.svg>
          <motion.svg
            className="h-151 w-235 md:mt-116 md:h-313 md:w-487"
            style={{
              gridArea: "1/1",
            }}
            width="487"
            height="313"
            viewBox="0 0 487 313"
            fill="none"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.7 }}
          >
            {/* でかmる */}
            <motion.path
              d="M211.842 41.9794C195.789 35.5728 178.285 32.0189 159.935 32.0189C82.475 32.0189 19.7137 94.8043 19.7137 172.241C19.7137 249.677 82.475 312.486 159.935 312.486C226.009 312.486 281.372 266.769 296.216 205.265"
              stroke="#FDC500"
              strokeWidth="0.8"
              strokeMiterlimit="10"
              variants={draw}
              custom={0.01}
            />
            {/* ちさまる*/}
            <motion.path
              d="M300.157 172.265C300.157 183.652 298.779 194.701 296.216 205.29C332.166 192.525 357.914 158.219 357.914 117.893C357.914 66.664 316.379 25.1294 265.15 25.1294C245.301 25.1294 226.927 31.391 211.841 42.0044C263.578 62.6508 300.157 113.179 300.157 172.265V172.265Z"
              stroke="#FDC500"
              strokeWidth="0.8"
              strokeMiterlimit="10"
              variants={draw}
              custom={0.03}
            />
            {/* うしろななめ */}
            <motion.path
              d="M296.214 205.265C256.469 184.908 211.84 94.1027 211.84 41.979"
              stroke="#FDC500"
              strokeWidth="0.8"
              strokeMiterlimit="10"
              variants={draw}
              custom={1}
            />
            {/* けつ */}
            <motion.path
              d="M0.998683 257.171L45.918 233.502"
              stroke="#FDC500"
              strokeWidth="0.8"
              strokeMiterlimit="10"
              variants={draw}
              custom={1}
            />
            {/* うえさきななめ */}
            <motion.path
              d="M125.095 191.726L402.226 45.5816"
              stroke="#FDC500"
              strokeWidth="0.8"
              strokeMiterlimit="10"
              variants={draw}
              custom={1}
            />
            {/* 先端 */}
            <motion.path
              d="M441.827 24.6931L486.722 1.00044L446.904 32.502"
              stroke="#FDC500"
              strokeWidth="0.8"
              strokeMiterlimit="10"
              variants={draw}
              custom={1.5}
            />
            {/* したさきななめ */}
            <motion.path
              d="M399.445 69.9992L328.27 126.281"
              stroke="#FDC500"
              strokeWidth="0.8"
              strokeMiterlimit="10"
              variants={draw}
              custom={2}
            />
            {/* さくみじかめ */}
            <motion.path
              d="M304.531 145.042L264.713 176.52"
              stroke="#FDC500"
              strokeWidth="0.8"
              strokeMiterlimit="10"
              variants={draw}
              custom={1}
            />
            {/* うしろななめ */}
            <motion.path
              d="M157.951 174.416L40.7936 98.2612"
              stroke="#FDC500"
              strokeWidth="0.8"
              strokeMiterlimit="10"
              variants={draw}
              custom={1}
            />
          </motion.svg>
        </div>
        <div
          className="mt-72 w-325 md:mt-0 md:w-570"
          style={{
            gridArea: "1/1",
          }}
        >
          <motion.h2
            className="sec-title h-70 overflow-hidden md:h-135"
            variants={ttlContainer}
            initial="init"
            whileInView="enter"
            viewport={{ once: true, amount: 0.5 }}
          >
            {ttl.split("").map((t, i) => {
              return (
                <motion.span
                  key={i}
                  className="inline-block [&:nth-child(7)]:ml-12px md:[&:nth-child(7)]:ml-24px"
                  variants={{
                    init: {
                      y: w < 834 ? 6.5 + "rem" : 8.75 + "rem",
                    },
                    enter: {
                      y: 0,
                      transition: {
                        duration: 0.6,
                        ease: "easeInOut",
                      },
                    },
                  }}
                >
                  {t}
                </motion.span>
              );
            })}
          </motion.h2>
          <motion.p
            className="mb-20 whitespace-pre-wrap text-14 leading-1.8 md:mt-[-30px] md:mb-40"
            variants={{
              init: {
                opacity: 0,
                y: 10,
              },
              enter: {
                opacity: 1,
                y: 0,
                transition: {
                  duration: 0.5,
                },
              },
            }}
            initial="init"
            whileInView="enter"
            viewport={{ once: true, amount: 0.5 }}
          >
            {t("whitepaperText")}
          </motion.p>
          <a
            href="https://ja.whitepaper.wholeearthfoundation.org/"
            className="group relative my-0 mr-0 ml-auto flex h-50 w-247 items-center"
            target="_blank"
            rel="noreferrer"
          >
            <span className="btn-link w-212">White Paper</span>
            <img
              src="/assets/img/common/btn-arrow.svg"
              className="absolute right-0 h-23 w-50 duration-[0.2s] group-hover:right-[-10px]"
              width="50"
              height="23"
              alt="White Paper"
            />
          </a>
        </div>
      </div>
    </section>
  );
};

export default Whitepaper;
