import React from "react";
import { useI18next } from "gatsby-plugin-react-i18next";
import { useLocation } from "@reach/router";
import { AnimatePresence, motion } from "framer-motion";
import { useWindowWidth } from "@react-hook/window-size";
import clsx from "clsx";

const draw = {
  hidden: { pathLength: 0, opacity: 0 },
  visible: (i: number) => {
    const delay = i * 0.5;
    return {
      pathLength: 1,
      opacity: 1,
      transition: {
        pathLength: { delay, duration: 1 },
        opacity: { delay, duration: 0.01 },
      },
    };
  },
};

const drawPath = {
  hidden: { pathLength: 0, opacity: 0 },
  visible: (i: number) => {
    const delay = i * 0.5;
    return {
      pathLength: 1,
      opacity: 1,
      transition: {
        pathLength: { delay, duration: 2 },
        opacity: { delay, duration: 0.01 },
      },
    };
  },
};

const ttlContainer = {
  init: {},
  enter: {
    transition: {
      staggerChildren: 0.05,
    },
  },
};

const About = () => {
  const { t } = useI18next();
  const location = useLocation();
  const currentDir = location.pathname.slice(0, 3);
  const w = useWindowWidth();

  const ttl = "About Us";
  return (
    <AnimatePresence>
      <section className="mt-328 pt-100 md:mt-428 md:pt-0" id="about">
        <div className="mx-auto grid w-325 md:w-1160">
          <motion.svg
            className="mt-36 ml-212 h-180 w-180 md:mt-0 md:ml-0 md:h-361 md:w-361"
            style={{
              gridArea: "1/1",
            }}
            width="361"
            height="361"
            viewBox="0 0 361 361"
            fill="none"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.2 }}
          >
            <motion.line y1="0.5" x2="360" y2="0.5" stroke="#A9C8E2" variants={draw} custom={0.1} />
            <motion.line y1="71.5" x2="360" y2="71.5" stroke="#A9C8E2" variants={draw} custom={0.5} />
            <motion.line y1="143.5" x2="360" y2="143.5" stroke="#A9C8E2" variants={draw} custom={0.3} />
            <motion.line y1="215.5" x2="360" y2="215.5" stroke="#A9C8E2" variants={draw} custom={0.2} />
            <motion.line y1="287.5" x2="360" y2="287.5" stroke="#A9C8E2" variants={draw} custom={0.1} />
            <motion.line y1="359.5" x2="360" y2="359.5" stroke="#A9C8E2" variants={draw} custom={0.3} />
            <motion.line x1="0.5" y1="1" x2="0.499984" y2="360" stroke="#A9C8E2" variants={draw} custom={0.9} />
            <motion.line x1="78.5" y1="1" x2="78.5" y2="360" stroke="#A9C8E2" variants={draw} custom={0.4} />
            <motion.line x1="150.5" y1="1" x2="150.5" y2="360" stroke="#A9C8E2" variants={draw} custom={0.3} />
            <motion.line x1="222.5" y1="1" x2="222.5" y2="360" stroke="#A9C8E2" variants={draw} custom={0.4} />
            <motion.line x1="294.5" y1="1" x2="294.5" y2="360" stroke="#A9C8E2" variants={draw} custom={0.3} />
            <motion.line
              x1="360.5"
              y1="2.18555e-08"
              x2="360.5"
              y2="360"
              stroke="#A9C8E2"
              variants={draw}
              custom={0.6}
            />
          </motion.svg>
          <motion.svg
            className="mt-82 ml-142 h-133 w-322 md:mt-94 md:ml-142 md:h-269 md:w-647"
            style={{
              gridArea: "1/1",
            }}
            width="647"
            height="269"
            viewBox="0 0 647 269"
            fill="none"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.4 }}
          >
            <motion.path
              d="M1 43.0978C137.795 -55.0943 204.665 18.0655 75.759 267C75.759 267 278.159 30.6939 231.368 267C231.368 267 826.961 -219.446 590.493 267"
              stroke="#FDC500"
              strokeWidth="0.8"
              variants={drawPath}
              custom={1}
            />
          </motion.svg>
          <motion.h2
            className="sec-title mt-0 h-64 overflow-hidden md:mt-171 md:ml-600 md:h-128"
            style={{
              gridArea: "1/1",
            }}
            variants={ttlContainer}
            initial="init"
            whileInView="enter"
            viewport={{ once: true, amount: 0.5 }}
          >
            {ttl.split("").map((t, i) => {
              return (
                <motion.span
                  key={i}
                  className="inline-block [&:nth-child(7)]:ml-11px md:[&:nth-child(7)]:ml-21px"
                  variants={{
                    init: {
                      y: w < 834 ? 3.75 + "rem" : 7.5 + "rem",
                    },
                    enter: {
                      y: 0,
                      transition: {
                        duration: 0.6,
                        ease: "easeInOut",
                      },
                    },
                  }}
                >
                  {t}
                </motion.span>
              );
            })}
          </motion.h2>
          <div
            className="mt-100 md:mt-290 md:ml-200"
            style={{
              gridArea: "1/1",
            }}
          >
            <div className="mb-20 flex-row-reverse items-end justify-end md:mb-40 md:flex">
              <div className="w-325 md:ml-40 md:w-560">
                <motion.p
                  className={clsx(
                    "mb-40 whitespace-pre-wrap text-28 leading-1.75 md:mb-20",
                    currentDir == "/ja" ? "font-mincho" : "font-condensed"
                  )}
                  variants={{
                    init: {
                      opacity: 0,
                      y: 10,
                    },
                    enter: {
                      opacity: 1,
                      y: 0,
                      transition: {
                        duration: 0.5,
                      },
                    },
                  }}
                  initial="init"
                  whileInView="enter"
                  viewport={{ once: true, amount: 0.5 }}
                >
                  {t("aboutHead")}
                </motion.p>
                <motion.p
                  className="mb-20 whitespace-pre-wrap text-14 leading-1.8 tracking-02 md:mb-0 md:w-auto"
                  variants={{
                    init: {
                      opacity: 0,
                      y: 10,
                    },
                    enter: {
                      opacity: 1,
                      y: 0,
                      transition: {
                        duration: 0.5,
                      },
                    },
                  }}
                  initial="init"
                  whileInView="enter"
                  viewport={{ once: true, amount: 0.5 }}
                >
                  {t("aboutText1")}
                </motion.p>
              </div>
              <picture>
                <source media="(max-width: 833px)" srcSet="/assets/img/about/pic-sp.jpg" />
                <motion.img
                  className="h-201 w-325 md:h-480 md:w-360"
                  src="/assets/img/about/pic.jpg"
                  width="360"
                  height="480"
                  alt="About Us"
                  variants={{
                    init: {
                      opacity: 0,
                      // y: 20,
                    },
                    enter: {
                      opacity: 1,
                      // y: 0,
                      transition: {
                        duration: 0.6,
                        ease: "easeInOut",
                      },
                    },
                  }}
                  initial="init"
                  whileInView="enter"
                  viewport={{ once: true, amount: 0.5 }}
                />
              </picture>
            </div>
            <div className="w-325 items-center md:flex md:w-867">
              <motion.p
                className="mb-20 whitespace-pre-wrap text-14 leading-1.8 md:mb-0 md:w-auto"
                variants={{
                  init: {
                    opacity: 0,
                    y: 10,
                  },
                  enter: {
                    opacity: 1,
                    y: 0,
                    transition: {
                      duration: 0.5,
                      // ease: [0.87, 0, 0.13, 1],
                    },
                  },
                }}
                initial="init"
                whileInView="enter"
                viewport={{ once: true, amount: 0.5 }}
              >
                {t("aboutText2")}
              </motion.p>
              <a href="#team" className="group relative my-0 ml-auto mr-0 flex h-50 w-190 items-center md:ml-117">
                <span className="btn-link w-155">Our Team</span>
                <img
                  src="/assets/img/common/btn-arrow.svg"
                  className="absolute right-0 h-23 w-50 duration-[0.2s] group-hover:right-[-3.1875rem] md:right-[-2.1875rem]"
                  width="50"
                  height="23"
                  alt="Our Team"
                />
              </a>
            </div>
          </div>
        </div>
      </section>
    </AnimatePresence>
  );
};

export default About;
