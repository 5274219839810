/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import { useGLTF } from "@react-three/drei";
import { Vector3 } from "three";
import { AnimatePresence, motion } from "framer-motion";
import { useWindowWidth } from "@react-hook/window-size";

const modelVariants = {
  offscreen: {
    opacity: 0,
  },
  onscreen: {
    opacity: 1,
    transition: {
      duration: 0.9,
      ease: [0.87, 0, 0.13, 1],
    },
  },
};

const Rig = ({ v = new Vector3() }) => {
  return useFrame((state) => {
    state.camera.position.lerp(v.set(-state.mouse.x / 2, -state.mouse.y / 2, 9), 0.05);
  });
};

const Model1 = (props: any) => {
  const { nodes, materials } = useGLTF("/assets/model/tool1.glb");

  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes["01"].geometry}
        material={materials["マテリアル.005"]}
        // position={[-0.61, 0.43, 0]}
        rotation={[1.79, -0.29, -0.91]}
        scale={0.03}
      />
    </group>
  );
};

const Model2 = (props: any) => {
  const { nodes, materials } = useGLTF("/assets/model/tool2.glb");

  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes["02"].geometry}
        material={materials["マテリアル.004"]}
        // position={[0.02, -0.58, 0]}
        rotation={[1.19, 0.25, -0.56]}
        scale={0.03}
      />
    </group>
  );
};

const Model3 = (props: any) => {
  const { nodes, materials } = useGLTF("/assets/model/tool3.glb");

  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes["03"].geometry}
        material={materials["マテリアル.003"]}
        // position={[0.38, 0.71, 0.03]}
        rotation={[1.42, -0.08, 0.5]}
        scale={0.03}
      />
    </group>
  );
};

useGLTF.preload("/assets/model/tool1.glb");
useGLTF.preload("/assets/model/tool2.glb");
useGLTF.preload("/assets/model/tool3.glb");

const ToolsModel = () => {
  const w = useWindowWidth();

  return (
    <AnimatePresence>
      <motion.div
        className="mt-550 ml-[-9rem] h-325 w-325 md:mt-60 md:ml-250 md:h-500 md:w-500"
        style={{
          gridArea: "1/1",
        }}
        variants={modelVariants}
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true, amount: 0.5 }}
      >
        <Canvas>
          <Rig />
          <Model1 position={[-1.83, 1.29, 0]} />
          <Model2 position={[0.06, -1.74, 0]} />
          <Model3 position={[1.14, 2.13, 0]} />
        </Canvas>
      </motion.div>
    </AnimatePresence>
  );
};

export default ToolsModel;
