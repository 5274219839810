import React from "react";
import { useI18next } from "gatsby-plugin-react-i18next";
import { useLocation } from "@reach/router";
import { motion, useTransform, useScroll } from "framer-motion";
import { useWindowWidth } from "@react-hook/window-size";
import EcoModel from "../model/EcosystemModel";
import clsx from "clsx";

const draw = {
  hidden: { pathLength: 0, opacity: 0 },
  visible: (i: number) => {
    const delay = i * 0.5;
    return {
      pathLength: 1,
      opacity: 1,
      transition: {
        pathLength: { delay, duration: 1 },
        opacity: { delay, duration: 0.01 },
      },
    };
  },
};

const ttlContainer = {
  init: {},
  enter: {
    transition: {
      staggerChildren: 0.05,
    },
  },
};

const Ecosystem = () => {
  const { t } = useI18next();
  const location = useLocation();
  const currentDir = location.pathname.slice(0, 3);

  const w = useWindowWidth();
  const { scrollY } = useScroll();
  const y = useTransform(scrollY, [0, 1000], [0, -50]);

  const ttl = "Ecosystem";

  return (
    <section className="mt-160 md:mt-325" id="ecosystem">
      <div className="mx-auto w-325 md:w-1160">
        <div className="grid">
          <div
            className="ml-0 grid md:ml-577"
            style={{
              gridArea: "1/1",
            }}
          >
            <motion.svg
              className="ml-171 mt-291 h-180 w-180 md:mt-227 md:ml-173 md:h-360 md:w-360"
              style={{
                gridArea: "1/1",
              }}
              width="361"
              height="361"
              viewBox="0 0 361 361"
              fill="none"
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.7 }}
            >
              <motion.line y1="0.5" x2="360" y2="0.5" stroke="#A9C8E2" variants={draw} custom={0.1} />
              <motion.line y1="71.5" x2="360" y2="71.5" stroke="#A9C8E2" variants={draw} custom={0.5} />
              <motion.line y1="143.5" x2="360" y2="143.5" stroke="#A9C8E2" variants={draw} custom={0.3} />
              <motion.line y1="215.5" x2="360" y2="215.5" stroke="#A9C8E2" variants={draw} custom={0.2} />
              <motion.line y1="287.5" x2="360" y2="287.5" stroke="#A9C8E2" variants={draw} custom={0.1} />
              <motion.line y1="359.5" x2="360" y2="359.5" stroke="#A9C8E2" variants={draw} custom={0.3} />
              <motion.line x1="0.5" y1="1" x2="0.499984" y2="360" stroke="#A9C8E2" variants={draw} custom={0.9} />
              <motion.line x1="78.5" y1="1" x2="78.5" y2="360" stroke="#A9C8E2" variants={draw} custom={0.4} />
              <motion.line x1="150.5" y1="1" x2="150.5" y2="360" stroke="#A9C8E2" variants={draw} custom={0.3} />
              <motion.line x1="222.5" y1="1" x2="222.5" y2="360" stroke="#A9C8E2" variants={draw} custom={0.4} />
              <motion.line x1="294.5" y1="1" x2="294.5" y2="360" stroke="#A9C8E2" variants={draw} custom={0.3} />
              <motion.line
                x1="360.5"
                y1="2.18555e-08"
                x2="360.5"
                y2="360"
                stroke="#A9C8E2"
                variants={draw}
                custom={0.6}
              />
            </motion.svg>
            <EcoModel />
            <motion.img
              className="pointer-events-none ml-121 h-261 w-200 translate-x-0 md:ml-0 md:h-480 md:w-360"
              src="/assets/img/ecosystem/pic.jpg"
              style={{
                gridArea: "1/1",
                // y: y,
              }}
              width="360"
              height="480"
              alt="Ecosystem"
              variants={{
                init: {
                  opacity: 0,
                  // y: 20,
                },
                enter: {
                  opacity: 1,
                  // y: 0,
                  transition: {
                    duration: 0.6,
                    ease: "easeInOut",
                  },
                },
              }}
              initial="init"
              whileInView="enter"
              viewport={{ once: true, amount: 0.5 }}
            />
          </div>
          <div
            className="mt-305 w-325 md:mt-159 md:w-560"
            style={{
              gridArea: "1/1",
            }}
          >
            <motion.h2
              className="sec-title mb-30 h-70 overflow-hidden md:mb-50 md:h-140"
              variants={ttlContainer}
              initial="init"
              whileInView="enter"
              viewport={{ once: true, amount: 0.5 }}
            >
              {ttl.split("").map((t, i) => {
                return (
                  <motion.span
                    key={i}
                    className="inline-block"
                    variants={{
                      init: {
                        y: w < 834 ? 6.5 + "rem" : 8.75 + "rem",
                      },
                      enter: {
                        y: 0,
                        transition: {
                          duration: 0.6,
                          ease: "easeInOut",
                        },
                      },
                    }}
                  >
                    {t}
                  </motion.span>
                );
              })}
            </motion.h2>
            <motion.p
              className={clsx(
                "whitespace-pre-wrap text-28 font-normal leading-1.75 tracking-01",
                currentDir == "/ja" ? "font-mincho" : "font-condensed"
              )}
              variants={{
                init: {
                  opacity: 0,
                  y: 10,
                },
                enter: {
                  opacity: 1,
                  y: 0,
                  transition: {
                    duration: 0.5,
                  },
                },
              }}
              initial="init"
              whileInView="enter"
              viewport={{ once: true, amount: 0.5 }}
            >
              {t("ecosystemHead")}
            </motion.p>
          </div>
        </div>
        <div className="items-center md:mt-[-3.125rem] md:grid">
          <img
            className="h-326 w-314 md:h-637 md:w-637"
            src="/assets/img/ecosystem/anim.gif"
            style={{
              gridArea: "1/1",
            }}
            width="637"
            height="637"
            alt="Ecosystem"
          />
          <motion.p
            className="w-325 whitespace-pre-wrap text-14 leading-1.8 md:ml-600 md:mt-0 md:w-560"
            style={{
              gridArea: "1/1",
            }}
            variants={{
              init: {
                opacity: 0,
                y: 10,
              },
              enter: {
                opacity: 1,
                y: 0,
                transition: {
                  duration: 0.5,
                },
              },
            }}
            initial="init"
            whileInView="enter"
            viewport={{ once: true, amount: 0.5 }}
          >
            {t("ecosystemText")}
          </motion.p>
        </div>
      </div>
    </section>
  );
};

export default Ecosystem;
