import React from "react";
import { motion } from "framer-motion";
import { useWindowWidth } from "@react-hook/window-size";
import { IMember } from "../../types";
import { Member } from "./Member";

interface ITeam {
  team: IMember[];
}

const ttlContainer = {
  init: {},
  enter: {
    transition: {
      staggerChildren: 0.05,
    },
  },
};

const cardContainer = {
  init: {},
  enter: {
    transition: {
      staggerChildren: 0.1,
    },
  },
};

const cardVariants = {
  init: {
    y: 20,
    opacity: 0,
  },
  enter: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 1,
    },
  },
  exit: {},
};

export const Team = ({ team }: ITeam) => {
  const w = useWindowWidth();
  const ttl = "Team";

  return (
    <section className="mt-150 md:mt-98 md:pt-150" id="team">
      <div className="mx-auto px-25 md:w-1160 md:p-0">
        <motion.h2
          className="sec-title h-64 overflow-hidden md:h-128"
          variants={ttlContainer}
          initial="init"
          whileInView="enter"
          viewport={{ once: true, amount: 0.5 }}
        >
          {ttl.split("").map((t, i) => {
            return (
              <motion.span
                key={i}
                className="inline-block"
                variants={{
                  init: {
                    y: w < 834 ? 3.75 + "rem" : 7.5 + "rem",
                  },
                  enter: {
                    y: 0,
                    transition: {
                      duration: 0.6,
                      ease: "easeInOut",
                    },
                  },
                }}
              >
                {t}
              </motion.span>
            );
          })}
        </motion.h2>
        <motion.ul
          className="mx-auto flex flex-wrap justify-center md:w-1043 md:gap-y-100 md:gap-x-58"
          variants={cardContainer}
          initial="init"
          whileInView="enter"
          viewport={{ once: true, amount: 0.1 }}
        >
          {team.map((item) => (
            <motion.li
              key={item.id}
              variants={cardVariants}
              className="w-153 md:w-217 [&:nth-child(2n)]:ml-19 md:[&:nth-child(2n)]:ml-0 [&:nth-child(n+3)]:mt-40 md:[&:nth-child(n+3)]:mt-0"
            >
              <Member {...item}></Member>
            </motion.li>
          ))}
        </motion.ul>
      </div>
    </section>
  );
};
